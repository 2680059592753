import { navigate } from "gatsby-link";
import React, { useState, useEffect } from "react";
import shapeBoxes from "../../images/shape_boxes.png";
import Faq from "../faq";
import {
  CarDetails,
  TyreDetails,
  CustomerDetails,
  nigeriaStates,
} from "../form/utils";
import { ActionBanner } from "./utils";

const faqData = [
  {
    id: 1,
    title: "Why should I be a Carviva Autocare customer?",
    text:
      "Carviva is a platform that connects car users and car owners to a wide network of modern OEM workshops, garages and autocare service providers across Nigeria.",
  },
  {
    id: 2,
    title: "How flexible is Carviva Autocare pricing?",
    text:
      "With Carviva, at the tap of a button on a smart phone, you can buy fuel, buy engine oil, buy airtime/Data while service appointments such as car wash, car diagnostics, lube change, wheels balancing, alignment e.t.c can be booked and paid for on-the-go and from the comfort of homes and offices.",
  },
  {
    id: 3,
    title: "How do car owners pay for service?",
    text:
      "Car owners pay cash on service delivery at service center or via prepaid card payments before visiting service center.",
  },
];

const TyreMidSection = () => {
  const [stage, setStage] = useState(1);
  const [states, setStates] = useState(null);
  const [allYears, setAllYears] = useState([]);
  const [allCars, setAllCars] = useState([]);
  const [carMakes, setCarMakes] = useState([]);
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carYear, setCarYear] = useState("");
  const [brands, setBrands] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [rims, setRims] = useState(null);
  const [widths, setWidths] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedRim, setSelectedRim] = useState("");
  const [selectedWidth, setSelectedWidth] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [custName, setCustName] = useState("");
  const [custPhone, setCustPhone] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custState, setCustState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hasError, setHasError] = useState(false);

  const url = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    constructYears();
    async function fetchStates() {
      const result = await nigeriaStates();
      setStates(result);
    }
    fetchStates();
    fetch(`${process.env.GATSBY_BASE_URL}vehicles`)
      .then((json) => json.json())
      .then((data) => {
        const tempVehicles = data.map((item) => {
          return { value: item, label: item };
        });
        setAllCars(tempVehicles);
      });
    fetch(`${process.env.GATSBY_BASE_URL}tyres/brands`)
      .then((json) => json.json())
      .then((data) => {
        const tyres = data.map((item) => {
          return { value: item, label: item };
        });
        setBrands(tyres);
      });
    fetch(`${process.env.GATSBY_BASE_URL}tyres/specs`)
      .then((json) => json.json())
      .then((data) => {
        const tempProfiles = data.profile.map((item) => {
          return { value: item, label: item };
        });
        const tempRims = data.rims.map((item) => {
          return { value: item, label: item };
        });
        const tempWidth = data.width.map((item) => {
          return { value: item, label: item };
        });
        setProfiles(tempProfiles);
        setRims(tempRims);
        setWidths(tempWidth);
      });
  }, []);

  const constructYears = () => {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (var year = currentYear - 21; year <= currentYear; year++) {
      years.push({ value: year, label: year });
    }
    setAllYears(years.reverse());
  };

  const handleCarMake = (selected) => {
    setCarMake(selected);
    fetch(`${process.env.GATSBY_BASE_URL}vehicles/${selected.value}`)
      .then((json) => json.json())
      .then((data) => {
        const sysm = data.map((item) => {
          return { value: item, label: item };
        });
        setCarMakes(sysm);
      });
  };

  const handleCarModel = (selected) => {
    setCarModel(selected);
    // setCarModel(selected.target.value);
  };

  const handleSelectedYear = (selected) => {
    setCarYear(selected);
  };

  const handleInputChange = (data, type) => {
    if (type === "name") {
      setCustName(data);
    } else if (type === "email") {
      setCustEmail(data);
    } else if (type === "phone") {
      setCustPhone(data);
    }
  };

  const handleFormAddition = (nextPage) => {
    const data = {
      car_system: "Tyre",
      quantity: Number(quantity),
      tyre_brand: selectedBrand.value,
      rim: selectedRim.value,
      width: selectedWidth.value,
      profile: selectedProfile.value,
    };
    setOrders((prevOrders) => [...prevOrders, data]);
    setSelectedBrand(null);
    setSelectedProfile(null);
    setSelectedRim(null);
    setSelectedWidth(null);
    if (nextPage) {
      setStage(3);
    }
  };

  const submitForm = () => {
    setIsLoading(true);
    const postData = {
      tyreQuote: {
        name: custName,
        email: custEmail,
        mobile: custPhone,
        car_make: carMake.value,
        car_model: carModel.value,
        car_year: `${carYear.value}`,
        location: custState.value,
        tyre_order: orders,
      },
    };
    fetch(`${process.env.GATSBY_BASE_URL}tyres/quote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("tyres order", data);
        if (data.message.includes("success")) {
          registerUser();
          navigate("/confirmation/", {
            state: { ref: data.tyreQuoteRef },
            replace: true,
          });
        } else {
          setHasError(true);
        }
      })
      .catch((error) => {
        // console.error("Form submit Error:", error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const registerUser = () => {
    // first_name, last_name, email, phone_number, channel, password, notification_id
    // Spare parts, Car trouble, Battery, Tyre
    const payload = {
      first_name: custName.split(" ")[0],
      last_name: custName.split(" ")[1],
      email: custEmail,
      phone_number: custPhone,
      channel: "Tyre",
    };
    console.log("registerUser", payload);
    fetch(`${process.env.GATSBY_API_URL}auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.text())
      .then((data) => console.log("silent register", data))
      .catch((error) => console.error("silent register: Error:", error));
  };

  return (
    <>
      <div className="home-midsection">
        <ActionBanner page={url.includes("tyre") ? "tyre" : null} />
        <div className="form-section">
          <img src={shapeBoxes} alt="shapes" className="shapes" />
          <div className="container">
            <h2>Please Fill Quote Form Below</h2>
            <div className="form-steps">
              <div className={`form-step ${stage === 1 ? "active" : null}`}>
                <h3>1</h3>
                <span>
                  Car <br /> details
                </span>
              </div>
              <div className={`form-step ${stage === 2 ? "active" : null}`}>
                <h3>2</h3>
                <span>
                  Tyre <br />
                  DETAILS
                </span>
              </div>
              <div className={`form-step ${stage === 3 ? "active" : null}`}>
                <h3>3</h3>
                <span>
                  Customer <br />
                  details
                </span>
              </div>
            </div>
          </div>
          <div className="container form-wrapper">
            <div className="form-level">
              <div className="form-level-bg"></div>
              <p>{stage}</p>
            </div>
            {stage === 1 && (
              <CarDetails
                cars={allCars}
                carMakes={carMakes}
                carMake={carMake}
                carModel={carModel}
                carYear={carYear}
                years={allYears}
                handleCarMake={handleCarMake}
                handleYear={handleSelectedYear}
                handleCarModel={handleCarModel}
                onSubmit={() => setStage(2)}
              />
            )}
            {stage === 2 && (
              <TyreDetails
                brands={brands}
                rims={rims}
                widths={widths}
                profiles={profiles}
                brand={selectedBrand}
                rim={selectedRim}
                profile={selectedProfile}
                width={selectedWidth}
                quantity={quantity}
                brandChange={(selected) => setSelectedBrand(selected)}
                rimChange={(selected) => setSelectedRim(selected)}
                profileChange={(selected) => setSelectedProfile(selected)}
                widthChange={(selected) => setSelectedWidth(selected)}
                quantityChange={(e) => setQuantity(e.target.value)}
                // addFormElement={handleFormAddition}
                onSubmit={() => handleFormAddition(true)}
              />
            )}
            {stage === 3 && (
              <CustomerDetails
                name={custName}
                email={custEmail}
                phone={custPhone}
                state={custState}
                states={states}
                handleInputChange={handleInputChange}
                changeState={(selected) => setCustState(selected)}
                isLoading={isLoading}
                onSubmit={submitForm}
                hasError={hasError}
              />
            )}
          </div>
        </div>
      </div>
      <div className="trust form">
        <div className="container center">
          <p>
            Our Car Trouble Ticket service welcomes you to a world where car
            owners are able to connect to trusted and verified mechanics by
            logging a Ticket. Forget your car headaches, no more trial and
            errors; let's discuss your car issues and our expert mechanics will
            find a fix!
          </p>
        </div>
      </div>
      <Faq questions={faqData} title="Top questions from customers" />
    </>
  );
};

export default TyreMidSection;
