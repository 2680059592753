import React from "react";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import TyreHeader from "../components/headers/tyre";
import TyreMidSection from "../components/contents/tyre";
import Footer from "../components/layout/footer";

const TyrePage = () => (
  <div className="homepage quotes">
    <Helmet />
    <TyreHeader />
    <TyreMidSection />
    <Footer />
  </div>
);

export default TyrePage;
